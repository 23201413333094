/** @jsx jsx */
// import React from 'react'
import { jsx } from '@emotion/react'
import { Link } from "gatsby"

const Slide = ({ content }) => (
  <div className="main_wrapper">
    <div className="">
        <div className="row intro_slide">
          <div className="col col-4 left_side">
            <Link
              to={'/drinks'}
              state={{content}}
              className="click_area">
                <div className="swipe_text">drink</div>
            </Link>
          </div>
          <div className="col col-4"></div>
          <div className="col col-4 right_side">
            <Link
              to={'/tasting'}
              state={{content}}
              className="click_area">
              <div className="swipe_text">learn</div>
            </Link>
          </div>
        </div>

    </div>

  </div>
)

export default Slide
