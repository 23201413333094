import React from "react";
import { graphql } from "gatsby"
import Slide from '../components/slide'


const IndexPage = ({ data }) => {

  const slides = [];

  for(var i = data.allWpSlider.edges.length-1; i >= 0; i--) {
    slides.push(data.allWpSlider.edges[i]);
  }

  return (

    <div className="slider_home">
      <div>
        <Slide content={slides} />
      </div>
    </div>


  )
}
export default IndexPage
export const query = graphql`
  query {
    allWpSlider {
      edges {
        node {
          id
          slug
          title
          slider_acf {
            description
            title
            subTitle
            productImage {
              sourceUrl
            }
            category
            boxContent {
              finish
              nose
              palate
            }
          }
        }
      }
    }
  }
`
